import { useEffect, useState } from "react";

function HeroImage({ breakpoints, imagePath }) {
  const [objectPosition, setObjectPosition] = useState({ x: 0, y: -100 });

  useEffect(() => {
    function handleObjectPosition() {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1920) {
        setObjectPosition(breakpoints.xxxLarge);
      } else if (windowWidth >= 1800) {
        setObjectPosition(breakpoints.xxLarge);
      } else if (windowWidth >= 1440) {
        setObjectPosition(breakpoints.extraLarge);
      } else if (windowWidth >= 768) {
        setObjectPosition(breakpoints.large);
      } else if (windowWidth >= 640) {
        setObjectPosition(breakpoints.medium);
      } else {
        setObjectPosition(breakpoints.small);
      }
    }

    window.addEventListener("resize", handleObjectPosition);

    handleObjectPosition();

    return () => {
      window.removeEventListener("resize", handleObjectPosition);
    };
  }, []);
  return (
    <div data-testid="search-properties-hero-image" className="mx-auto w-full relative flex items-center">
      <picture className="w-full">
        <source srcSet={imagePath} media="(min-width: 220px)" />
        <img
          src={imagePath}
          alt="search properties hero"
          className="h-64 md:h-96 w-full object-cover"
          style={{
            objectPosition: `${objectPosition.x}px ${objectPosition.y}px`,
          }}
        />
      </picture>
    </div>
  );
}

export default HeroImage;
